export default [
  {
    path: '/auth',
    component: () => import('@/layouts/LayoutAuth'),
    children: [
      {
        name: 'loginPage',
        path: '/auth/login',
        meta: {
          title: 'page.login',
          description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
        },
        component: () => import('@/pages/auth/Login'),
      },
      {
        name: 'signUpPage',
        path: '/auth/sign-up',
        meta: {
          title: 'page.signUp',
          description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
        },
        component: () => import('@/pages/auth/SignUp'),
      },
      {
        name: 'restorePage',
        path: '/auth/restore',
        meta: {
          title: 'page.resetPassword',
          description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
        },
        component: () => import('@/pages/auth/Restore'),
      },
      {
        name: 'recoveryPassword',
        path: '/auth/recovery-password/:token',
        meta: {
          title: 'page.resetPassword',
          description: 'Page Drafter is a free tool that helps you create professional website layouts in minutes.',
        },
        component: () => import('@/pages/auth/RecoveryPassword'),
      },
      {
        name: 'publicAuth',
        path: '/public/token/:token',
      },
    ],
  },
]
